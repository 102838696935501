<template>
    <ui-create
        name="gridOption"
        :title="title"
        :load="load"
    >
        <template #body >

            <v-form ref="form" >
                <v-row>
                    <v-col
                        cols="12"
                        sm="4"
                    >
                        <v-text-field
                            label="Descrição"
                            v-model="form.descricao"
                            counter
                            maxlength="64"
                            :rules="[
                                form_descricao_rules.required, form_descricao_rules.counter,
                                    form_descricao_rules.min
                            ]"
                        ></v-text-field>
                    </v-col>

                    <v-col
                        cols="12"
                        sm="4"
                    >
                        <v-autocomplete
                            label="Grade"
                            v-model="form.id_grade"
                            :items="sectionsGrid"
                            item-text="descricao"
                            item-value="id"
                            :rules="[form_grade_rules.required]"
                        ></v-autocomplete>
                    </v-col>

                    <v-col
                        cols="12"
                        sm="4"
                    >
                        <vuetify-money
                            label="Valor"
                            v-model="form.valor"
                            v-bind:options="options_form_valor"
                                :rules="[form_valor_rules.required, form_valor_rules.counter]"
                        ></vuetify-money>
                    </v-col>
                </v-row>
            </v-form>

        </template>
    </ui-create>
</template>
<script>
import uiCreate from '@/templates/create'
export default {
    name: 'vwgridOptionCreate',
    components: {
        uiCreate
    },
    data(){
        return {
            load: false,
            form: {
                descricao: '',
                id_grade: null,
                valor: ''
            },
            alert: {
                show: false,
                type: 'indigo',
                text: ''
            },
            alertSuccess: {
                show: false,
                type: 'indigo',
                text: ''
            },

            sectionsGrid: [],

            form_descricao_rules: {
                required: value => !!value || 'Descrição é obrigatória.',
                counter: value => value.length <= 64 || 'Dados inválido!',
                min: v => v.length >= 2 || 'Mínimo de 2 caracteres'
            },

            form_grade_rules: {
                required: value => !!value || 'Selecione uma grade.'
            },

            options_form_valor: {
                prefix: "R$",
                length: 4,
                precision: 2
            },
            form_valor_rules: {
                required: value => !!value || 'Valor é obrigatório.',
                counter: value => value.length <= 5 || 'Dados inválido!'
            }
        }
    },

    computed:{
        id: function(){
            return this.$route.params.id
        },

        title(){
            return this.id ? `Editar Opção de Grid: ${this.id}` : 'Cadastrar Opção de Grid'
        }
    },

    methods: {
        clearForm: function() {
            for (let prop in this.form) {
                this.form[prop] = ''
            }
        },
    
        save: function(){
            if(this.$refs.form.validate()){
                this.overlay = true
                if(this.id !== undefined){
                    this.update()
                }else{
                    this.create()
                }
            }
        },

        update: function(){
            this.overlay = true
            fetch(process.env.VUE_APP_API + '/grid-options/update/' + this.id, {
                method: 'PUT',
                headers: new Headers({
                    'Authentication': this.$store.getters.token,
                    'Data': JSON.stringify(this.form)
                })
            })
                .then(res => res.json())
                .then(() => {
                    this.$root.$emit('open-snackbar-on-grid', 'Grade cadastrada com sucesso!')
                    this.returnRead()
                })
                .catch(error => {
                    this.$root.$emit('open-snackbar-on-grid', error.message)
                })
        },

        create: function(){
            this.overlay = true
            fetch(process.env.VUE_APP_API + '/grid-options/create', {
                method: 'POST',
                headers: new Headers({
                    'Authentication': this.$store.getters.token,
                    'Data': JSON.stringify(this.form)
                })
            })
                .then(res => res.json())
                .then(() => {
                    this.$root.$emit('open-snackbar-on-grid', 'Grade cadastrada com sucesso!')
                    this.clearForm()
                })
                .catch(error => {
                    this.$root.$emit('open-snackbar-on-grid', error.message)
                })
        },

        getData: function(){
            this.overlay = true
            fetch(process.env.VUE_APP_API + '/grid-options/read/' + this.id, {
                method: 'GET',
                headers: new Headers({
                    'Authentication': this.$store.getters.token
                })
            })
                .then(res => res.json())
                .then(resJSON => {
                    console.log(resJSON)
                    this.form = resJSON.response[0].listhelper[0]
                })
                .catch(error => {
                    this.$root.$emit('open-snackbar-on-grid', error.message)
                })
        },

        getSections: function(){
            fetch(process.env.VUE_APP_API + '/generics/description/grades', {
                method: 'GET',
                headers: new Headers({
                    'Authentication': this.$store.getters.token
                })
            })
                .then(res => res.json())
                .then(resJSON => {
                    console.log(resJSON)
                    this.sectionsGrid = resJSON.response
                })
                .catch(error => {
                    this.$root.$emit('open-snackbar-on-grid', error.message)
                })
        }
    },

    mounted(){
        if (this.id != undefined) {
            this.getData()
        }

        this.getSections()

        this.$root.$on('save-changes-on-gridOption', () => {
            this.$nextTick(() => {
                this.save()
            })
        })
    }
}
</script>