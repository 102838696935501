<template>
    <v-container>
        <v-snackbar
            v-model="alert.show"
            :timeout="3000"
            :color="alert.type"
        >
            {{alert.text}}

            <template v-slot:action="{ attrs }">
                <v-btn
                    color="blue"
                    text
                    v-bind="attrs"
                    @click="alert.show = false"
                >
                    Fechar
                </v-btn>
            </template>
        </v-snackbar>
        <v-card>
            <v-card-title>
                {{title}}

                <v-spacer></v-spacer>

                <v-btn
                    color="indigo darken-1"
                    dark
                    fab
                    small
                    :to="{name: `${name}.read`}"
                >
                    <v-icon>
                        mdi-arrow-left
                    </v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text>
                <v-form ref="form" >
                    <slot name="body" >
                    </slot>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-btn
                    color="indigo darken-1"
                    dark
                   @click.prevent="save()"
                >
                    <v-icon>
                        mdi-save
                    </v-icon>

                    Salvar
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn
                    color="pink darken-1"
                    dark
                    @click.prevent="cancel()"
                >
                    <v-icon>
                        mdi-block
                    </v-icon>

                    Cancelar
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-overlay :value="overlay">
            <v-progress-circular
                size="100"
                color="indigo darken-1"
            >
            </v-progress-circular>
        </v-overlay>
    </v-container>
</template>

<script>
    import { mapActions } from 'vuex'
    export default {
        name: 'templateCreate',

        props: {
            value: {
                type: Object,
                required: false,
                default(){
                    return {}
                }
            },
            name: {
                type: String,
                required: true
            },

            title: {
                type: String,
                required: true
            },

            id: {
                type: Number,
                required: false,
                default: 0,
            }
        },

        data() {
            return {
                alert: {
                    show: false,
                    type: 'indigo',
                    text: ''
                },
                overlay: false
            }
        },

        computed: {
            isLoading(){
                return this.load
            }
        },

        methods: {
            ...mapActions({
                reAuth: '_AUTH'
            }),
            save(){
                if(this.$refs.form.validate()){
                    if (this.id > 0) {
                        this.update()
                    }else{
                        this.create()
                    }
                }else{
                    this.$set(this.alert, 'show', true)
                    this.$set(this.alert, 'text', 'Existem campos inválidos.')
                }
            },

            cancel(){
                this.$router.push({ name: `${this.name}.read` })
            },

            create(){
                this.overlay = true
                fetch(`${process.env.VUE_APP_API}/${this.name}/create`, {
                    method: 'POST',
                    headers: new Headers({
                        'Authentication': this.$store.getters.token,
                        'Data': JSON.stringify(this.value)
                    })
                })
                    .then(res => {
                        if (res.status === 401) {
                            this.reAuth()
                                .finally(this.create())
                        }else if(res.status === 200){
                            res.json()
                                .then(resJSON => {
                                    console.log(resJSON)
                                })
                        }else{
                            console.error('Error on get data', '=>', res.body)
                        }
                    })
                    .catch(error => {
                        console.error(error)

                    })
                    .finally(() => {
                        this.overlay = false
                        this.$root.$emit('input', this.value)
                    })
            },

            update(){
                this.overlay = true
                fetch(`${process.env.VUE_APP_API}/${this.name}/update/${this.id}`, {
                    method: 'PUT',
                    headers: new Headers({
                        'Authentication': this.$store.getters.token,
                        'Data': JSON.stringify(this.value)
                    })
                })
                    .then(res => {
                        if (res.status === 401) {
                            this.reAuth()
                                .finally(this.update())
                        }else if(res.status === 200){
                            res.json()
                                .then(resJSON => {
                                    console.log(resJSON)
                                })
                        }else{
                            console.error('Error on get data', '=>', res.body)
                        }
                    })
                    .catch(error => {
                        console.error(error)
                    })
                    .finally(() => {
                        this.overlay = false
                        this.$root.$emit('input', this.value)
                    })
            },
            
            getData(){
                this.overlay = true
                fetch(`${process.env.VUE_APP_API}/${this.name}/read/${this.id}`, {
                    method: 'GET',
                    headers: new Headers({
                        'Authentication': this.$store.getters.token
                    })
                })
                    .then(res => {
                        if (res.status === 401) {
                            this.reAuth()
                                .finally(this.getData())
                        }else if(res.status === 200){
                            res.json()
                                .then(resJSON => {
                                    this.$root.$emit(`load-data-${this.name}`, resJSON.response[0].listhelper[0])         
                                })
                        }else{
                            console.error('Error on get data', '=>', res.body)
                        }
                    })
                    .catch(error => {
                        console.error(error)

                    })
                    .finally(() => {
                        this.overlay = false
                    })
            }
        },

        mounted(){
            this.$root.$on(`open-snackbar-on-${this.name}`, (message) => {
                this.$set(this.alert, 'show', true)
                this.$set(this.alert, 'text', message)
            })

            if (this.id > 0) this.getData()
        }
    }
</script>
<style>

</style>